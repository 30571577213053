export const initialState = {
    step: "type",
    chair: null,
    type: null,
    palette: null
}

export function reducer(state, action) {
    console.log(action)

    switch (action.type) {
        case 'PROGRESS':
            return { ...state, progress: {...state.progress, step: action.payload.step  }}
        case 'CHAIR':
            return { ...state, progress: {...state.progress, chair:{ ...action.payload.chair } }}
        case 'CHAIR_PROGRESS':
            return { ...state, progress: { ...state.progress, ...action.payload } }
        case 'PALETTE':
            return { ...state, progress: { ...state.progress, palette: action.payload } }
        default:
            return state
    }

}