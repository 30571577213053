import React, { useState, useEffect } from 'react';
import { useGlobalState, emptyState, options } from "../state/index"
import { Front, Side } from '../components/illustrations/Illustrations';
import ChairModal from "./ChairModal"
import SubTypeSelect from "../components/SubTypeSelect"
import ColorRotator from "../components/ColorRotator"
import Detail from "../components/Detail"

import '../css/carousel.css'
import '../css/global.css';




export default function TypeSelect(props) {
    const globalState = useGlobalState()
    const [model, setModel] = useState(false)
    const [op, setOp] = useState(options.armChair)

    let mobile = false
    if (window.innerWidth < 768) {
       mobile = true;
    }
    const [isTabletOrMobile, setIsTabletOrMobile] = useState(mobile);
    useEffect(() => {
    function handleResize() {
        if (window.innerWidth < 768) {
            setIsTabletOrMobile(true)
        } else {
            setIsTabletOrMobile(false)
        }
        }
        window.addEventListener('resize', handleResize)
    })

    let vw = 50
    if(!isTabletOrMobile){
        vw = 18
    }
    function sm(chair) {
        if(chair.type==="armChair"){
            setOp(options.armChair)
        }
        if(chair.type==="sideChair"){
            setOp(options.sideChair)
        }
        if(chair.type==="bench"){
            setOp(options.bench)
        }
        if(chair.type==="stool"){
            setOp(options.stool)
        }
        setModel(chair)
    }

    function Selector(props){
        const elements = []

        for(const i of items){
            if(i.key === "bench"){
                elements.push(
                    <span key="bench"  className="carousel-item">
                        <Front type="bench" vw={vw} chair={emptyState.bench} />
                    </span>
               )
            }
            if(i.key === "sideChair"){
                elements.push(
                    <span key="side-chair" className="carousel-item">
                        <Side type="sideChair" vw={vw} chair={emptyState.sideChair} />
                    </span>
               )
            }
            if(i.key === "armChair"){
                elements.push(
                    <span key="arm-chair" className="carousel-item" >
                        <Front type="armChair" vw={vw} chair={emptyState.armChair} />
                    </span>
               )
            }
            if(i.key === "stool"){
                elements.push(
                    <span key="stool" className="carousel-item" >
                        <Front type="stool" vw={vw} chair={emptyState.stool} />
                    </span>
               )
            }

            if(i.key === "chaise"){
                elements.push(
                    <span key="chaise" className="carousel-item" >
                        <Front type="chaise" vw={vw} chair={emptyState.chaise} />
                    </span>
               )
            }
        }

        return elements
    }
    
    console.log(globalState.state.armChair)
    let initialItems = [
        {
            key:"bench",
            state:globalState.state.bench
        },
        {
            key:"sideChair",
            state:globalState.state.sideChair
        },
        {
            key:"armChair",
            state:globalState.state.armChair
        },
        {
            key:"stool",
            state:globalState.state.stool
        },

        {
            key:"chaise",
            state:globalState.state.chaise
        },

    ]
   

    const [items, setItems] = useState(initialItems)

    function shiftLeft() {
        items.unshift(items.pop())

        setItems([...items])
    }
    function shiftRight() {
        items.push(items.shift())

        setItems([...items])
    }

    useEffect(()=>{
        for(const i in items){
            if(items[i].key == "bench"){
                items[i].state = globalState.state.bench
            }
            if(items[i].key == "armChair"){
                items[i].state = globalState.state.armChair
            }
            if(items[i].key == "sideChair"){
                items[i].state = globalState.state.sideChair
            }
        }        
        setItems([...items])
    },[globalState.state.bench,globalState.state.armChair,globalState.state.sideChair])

    function getItems(key){
        for(const i in items){
            if(items[i].key == key){
                return items[i]
            }
        }
    }

    return <div className={(!!props.selected) ? "preselected" : ""}>
        <ColorRotator></ColorRotator>
        {model && !props.selected &&
            <ChairModal state={globalState} close={() => { setModel(false) }} chair={model} />
        }
        <div className="carousel">
            {!props.selected &&
                <div>
                    <div onClick={shiftLeft} className="left-handle">&lt;</div>
                    <div onClick={shiftRight} className="right-handle">&gt;</div>
                </div>
            }
            <Selector></Selector>

        
        </div>
        <Detail type={items[2].key} ></Detail>
     
    </div>
}