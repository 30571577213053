import React from 'react'
import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = 'https://stacklab.us3.list-manage.com/subscribe/post?u=1f2988c254932c6d4dbba88f5&amp;id=15664813fd'


// simplest form (only email)
// a basic form
const SimpleForm = ({ status, message, className, style, onSubmitted }) => {
    let input;
    const submit = () =>
      input &&
      input.value.indexOf("@") > -1 &&
      onSubmitted({
        EMAIL: input.value
      });
  
    return (
      <div className={className} style={style}>
        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <input
          ref={node => (input = node)}
          type="email"
          placeholder="Your email"
        />
        <button onClick={submit}>Submit</button>
      </div>
    );
  };
  
// use the render prop and your custom form
const CustomForm = (props) => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <div>
        <p>Beta will be avaliable spring 2021. 
            Enter your email below and we will contact you as soon as it's ready </p>
        <SimpleForm onSubmitted={formData => subscribe(formData)} />
        {status === "sending" && <div >sending...</div>}
        {status === "error" && <div  dangerouslySetInnerHTML={{__html: message}}/>}
        {status === "success" && <div >We will contact you when the beta is ready</div>}
        <div className="close"><button onClick={props.close}>CLOSE</button></div>
      </div>
    )}
  />
  
)

export default CustomForm