import React from 'react';
import { useGlobalState } from "../state/index"
import {cloneDeep} from 'lodash';

import { Top } from '../components/illustrations/Illustrations';

import "../css/flex.css"
import "../css/layouts.css"


export default function Radius(props) {
    const globalState = useGlobalState()

    const squeared = cloneDeep(globalState.state.progress.chair);
    squeared.radiusCorner = false;

    const rounded = cloneDeep(globalState.state.progress.chair);
    rounded.radiusCorner = true;

    return <div className="two-column-with-descr legs">

        <div className="content">
            <div className="cel">
                <Top vw={25} type={squeared.type} chair={squeared}></Top>
            </div>
            <div className="cel">
                <Top vw={25}  type={rounded.type} chair={rounded}></Top>
            </div>
            <div className="cel">
               <div className="sub-sub-title">Squared</div>
            </div>
            <div className="cel">
                <div className="sub-sub-title">Rounded</div>
            </div>
        
            
        </div>
       
      

    </div>
}