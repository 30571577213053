import React, {useEffect} from 'react';
import {useGlobalState} from "../state/index"
import {cloneDeep} from 'lodash';

const pallets = {
    /*soft:['#CB997E', '#EDDCD2' ,'#FFF1E6', '#F0EFEB', '#DDBEA9', '#A5A58D', '#B7B7A4'],
    sats: ['#003049', '#D62828', '#F77F00', '#FCBF49', '#EAE2B7'],
    sad: ['#001427', '#708D81', '#F4D58D', '#BF0603', '#8D0801'],
    beach: ['#FF9F1C', '#FFBF69', '#FFFFFF', '#CBF3F0', '#2EC4B6'],
    unicorn: ['#FF99C8', '#FCF6BD', '#D0F4DE', '#A9DEF9', '#E4C1F9'],
    charmed: ['#26547C','#EF476F' ,'#FFD166', '#06D6A0', '#FFFCF9'],*/
    felt:[
        '#9F2063', '#E9B856',  '#BA4637', '#842629','#653C53',
        '#632D2A', '#40342B', '#566D36', '#386B4D' , '#274636',
        '#2D6364', '#334F77', '#25409D', '#1F2A4B' , '#E7E3C9',
        '#D1C6B8', '#AFADAA', '#A49D93', '#676668', '#404348',
        '#583937', '#7D6254', '#C7B29F', '#9A9271',
        '#E79746', '#AA4327', '#AE3C32', '#C83A37',
        '#4A2E47', '#5796B1', '#1B3DAD', '#2E3E72',
        '#E7E4D6', '#C8B990', '#B6B2A1', '#A79E85',
        '#8A8B82', '#6E6F6B', '#615B50', '#3F3C32',
        '#3D3D3D', '#353535', '#2A231D', '#191919'
    ]
}


const seats = {
    single: { 
        seat: {
            width: 24,
            depth: 20,
            blocks: [
                { layers:24, color: "white" },
            ]   
        },
        legs: {
            height: 8,
            width: 5,
            color: 'black'
        }   
    },
    double: { 
        seat: {
            width: 24,
            depth: 20,
            blocks: [
                { layers: 24, color: "white" },
                { layers: 24, color: "white" },
            ]   
        },
        legs: {
            height: 4,
            width: 5,
            color: 'black'
        }   
    },
    tripple: { 
        seat: {
            width: 24,
            depth: 20,
            blocks: [
                { layers: 16, color: "white" },
                { layers: 16, color: "white" },
                { layers: 16, color: "white" },
            ]   
        },
        legs: {
            height: 4,
            width: 5,
            color: 'black'
        }   
    },
    line: { 
        seat: {
            width: 24,
            depth: 20,
            blocks: [
                { layers: 22, color: "white" },
                { layers: 1, color: "white" },
                { layers: 22, color: "white" },
            ]   
        },
        legs: {
            height: 4,
            width: 5,
            color: 'black'
        }   
    },

}

const sideBacks = {
    single: {
        back: {
            depth: 5,
            blocks: [
                { layers: 62, color: "white" },
            ]
        },
    },
    double: {
        back: {
            depth: 5,
            blocks: [
                { layers: 32, color: "white" },
                { layers: 32, color: "white" },
            ]
        },
    },
    tripple: {
        back: {
            depth: 5,
            blocks: [
                { layers: 20, color: "white" },
                { layers: 20, color: "white" },
                { layers: 22, color: "white" },
            ]
        },
    },
}

const armBacks = {
    single: {
        back: {
            depth: 5,
            blocks: [
                { layers: 36, color: "white" },
            ]
        }
    },
    double:{
        back: {
            depth: 5,
            blocks: [
                { layers: 18, color: "white" },
                { layers: 18, color: "white" },
            ]
        }
    },
    tripple:{
        back: {
            depth: 5,
            blocks: [
                { layers: 12, color: "white" },
                { layers: 12, color: "white" },
                { layers: 12, color: "white" },
            ]
        }
    }
}

const arms = {
    single:{
        arm: {
            width: 6,
            blocks: [
                { layers: 36, color: "white" }
            ]
        }
    },
    double:{
        arm: {
            width: 6,
            blocks: [
                { layers: 18, color: "white" },
                { layers: 18, color: "white" }
            ]
        }
    },
    tripple:{
        arm: {
            width: 6,
            blocks: [
                { layers: 12, color: "white" },
                { layers: 12, color: "white" },
                { layers: 12, color: "white" }
            ]
        }
    }
}

const chaiseParts = {
    single:{
        arm: {
            width: 6,
            blocks: [
                { layers: 12, color: "white" }
            ]
        }
    },
    double:{
        arm: {
            width: 6,
            blocks: [
                { layers: 12, color: "white" },
                { layers: 12, color: "white" }
            ]
        }
    },
    tripple:{
        arm: {
            width: 6,
            blocks: [
                { layers: 5, color: "white" },
                { layers: 3, color: "white" },
                { layers: 5, color: "white" }
            ]
        }
    }
}

function randomizeBlocks(blocks, pallet){
    const keys = Object.keys(pallet)
    for(const b in blocks){
        blocks[b].color = pallet[keys[ keys.length * Math.random() << 0]];
    }
}

function randomArmChair(armChair, pallet){
    const ak = Object.keys(arms)
    const bk = Object.keys(armBacks)
    const sk = Object.keys(seats)

    const p = cloneDeep(seats[sk[sk.length * Math.random() << 0]]);
    console.log(p)
    armChair.seat = p.seat
    armChair.legs = p.legs
    randomizeBlocks(armChair.seat.blocks, pallet)

    armChair.leftArm = arms[ak[ ak.length * Math.random() << 0]].arm
    randomizeBlocks(armChair.leftArm.blocks, pallet)

    armChair.rightArm = arms[ak[ ak.length * Math.random() << 0]].arm
    randomizeBlocks(armChair.rightArm.blocks, pallet)

    armChair.back = armBacks[bk[ bk.length * Math.random() << 0]].back
    randomizeBlocks(armChair.back.blocks, pallet)

    return cloneDeep(armChair)
}

function randomSideChair(sideChair, pallet){
    const bk = Object.keys(sideBacks)
    const sk = Object.keys(seats)

    const p = cloneDeep(seats[sk[sk.length * Math.random() << 0]]);
    sideChair.seat = p.seat
    sideChair.legs = p.legs
    randomizeBlocks(sideChair.seat.blocks, pallet)
    
    sideChair.back = sideBacks[bk[ bk.length * Math.random() << 0]].back
    randomizeBlocks(sideChair.back.blocks, pallet)

    return cloneDeep(sideChair)
}

function randomBench(bench, pallet){
    const sk = Object.keys(seats)

    const p = cloneDeep(seats[sk[sk.length * Math.random() << 0]]);
    bench.seat = p.seat
    bench.legs = p.legs
    randomizeBlocks(bench.seat.blocks, pallet)

    return cloneDeep(bench)
}

function randomStool(stool, pallet){
    const sk = Object.keys(seats)

    const p = cloneDeep(seats[sk[sk.length * Math.random() << 0]]);
    stool.seat = p.seat
    stool.seat.width = 18
    stool.legs = p.legs
    stool.legs.width = 8;
    randomizeBlocks(stool.seat.blocks, pallet)

    return cloneDeep(stool)
}

function randomChaise(chaise, pallet){
    const ak = Object.keys(chaiseParts)
    const bk = Object.keys(armBacks)
    const sk = Object.keys(seats)

    const p = cloneDeep(seats[sk[sk.length * Math.random() << 0]]);
    chaise.seat = p.seat
    chaise.seat.width = 30
    chaise.legs = p.legs
    randomizeBlocks(chaise.seat.blocks, pallet)

    chaise.leftArm = chaiseParts[ak[ ak.length * Math.random() << 0]].arm
    randomizeBlocks(chaise.leftArm.blocks, pallet)

    chaise.back = armBacks[bk[ bk.length * Math.random() << 0]].back
    chaise.back.width = 18
    randomizeBlocks(chaise.back.blocks, pallet)

    return cloneDeep(chaise)
}

export default function ColorRotator(props){
    const globalState = useGlobalState()
    const dispatch = globalState.dispatch 

    useEffect(() => {
        const timer = setInterval(() => {
            const keys = Object.keys(pallets)
            const p = pallets[keys[keys.length * Math.random() << 0]];
            dispatch({
                type:"PALETTE",
                payload: p
            })
            dispatch({
                type:"ARM_CHAIR",
                payload: randomArmChair(globalState.state.armChair, p)
            })
            dispatch({
                type:"BENCH",
                payload: randomBench(globalState.state.bench, p)
            })
            dispatch({
                type:"SIDE_CHAIR",
                payload: randomSideChair(globalState.state.sideChair, p)
            })
            dispatch({
                type:"STOOL",
                payload: randomStool(globalState.state.stool, p)
            })
            dispatch({
                type:"CHAISE",
                payload: randomChaise(globalState.state.chaise, p)
            })
        }, 2000);
        return () => clearTimeout(timer);
      }, []);

    return null;
}