import React from 'react';
import { useGlobalState } from "../state/index"
import {cloneDeep} from 'lodash';

import { Front, Side } from '../components/illustrations/Illustrations';

import "../css/flex.css"
import "../css/layouts.css"


export default function Legs(props) {
    const globalState = useGlobalState()
    
    console.log(globalState.state)
    const smallLegs = cloneDeep(globalState.state.progress.chair);
    smallLegs.legs.width = 4;

    const largeLegs = cloneDeep(globalState.state.progress.chair);
    largeLegs.legs.width = 8;


   function smallCallback(){
        globalState.dispatch(
        {
            type:"CHAIR_PROGRESS", 
            payload:{
                chair:smallLegs, 
                step: "corners"
            }
        }
    )}
    
    function largeCallback(){
        
        globalState.dispatch(
        {
            type:"CHAIR_PROGRESS", 
            payload:{
                chair:largeLegs, 
                step: "corners"
            }
        }
    )}

    return <div className="three-column-with-descr legs">
        <div className=" panel left">
            <div className="sub-sub-title">
                4" legs
            </div>
            <div className="details">
            </div>
        </div>
        <div className="content">
            <div className="cel clickable" onClick={smallCallback}>
                <Front vw={15} type={smallLegs.type} chair={smallLegs}></Front>
            </div>
            <div className="cel "></div>
            <div className="cel clickable" onClick={largeCallback}>
                <Front vw={15}  type={largeLegs.type} chair={largeLegs}></Front>
            </div>
            <div className="cel clickable" onClick={smallCallback}>
                <Side vw={15} type={smallLegs.type} chair={smallLegs}></Side>
            </div>
            <div className="cel "></div>

            <div className="cel clickable" onClick={largeCallback}>
                <Side vw={15} type={largeLegs.type} chair={largeLegs}></Side>
            </div>
        </div>
        <div className=" panel right">
            <div className="sub-sub-title">
                8" legs
            </div>
            <div className="details">
            </div>
        </div>

    </div>
}