export default {
    chairs: {
        'armChair': {
            name: "Arm Chair",
            dimensions: '18 2/4 x 29 9/16 x 32 5/16"',
            startingPrice: "3,000"
        },
        'bench': {
            name: "Bench",
            dimensions: '18 x 32 x 16" H',
            startingPrice: "3,000"
        },
        'sideChair': {
            name: "Side Chair",
            dimensions: '18 3/4 x 29 9/16 x 32 5/16" H',
            startingPrice: "3,000"
        }
    }
}