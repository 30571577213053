import React from 'react';
import { Stage, Layer, Rect, Circle, Group } from 'react-konva';
import { inch,cornerRadius } from './utils'


export default function ArmChairBottom(props) {
    const chair = props.chair
    const largestInch = Math.max(chair.seat.depth, chair.seat.width)

    const _in = (i) => { return inch(i, largestInch, props.size) };

    return <Stage width={_in(chair.seat.width) + 2} height={_in(chair.seat.depth) + 2}>
        <Layer>
            <Rect
                x={0}
                y={0}
                width={_in(chair.seat.width)}
                height={_in(chair.seat.depth)}
                fill={"silver"}
                stroke='black'
                strokeWidth={1}
                cornerRadius={cornerRadius(chair,_in)}

            />

            <Circle
                x={_in(chair.legs.width / 2)}
                y={_in(chair.legs.width / 2)}
                radius={_in(chair.legs.width / 2)}
                stroke='black'
                strokeWidth={1}
                fill={"silver"}
            />
            <Circle
                x={_in(chair.seat.width - chair.legs.width / 2)}
                y={_in(chair.legs.width / 2)}
                radius={_in(chair.legs.width / 2)}
                stroke='black'
                strokeWidth={1}
                fill={"silver"}

            />

            <Circle
                x={_in(chair.legs.width / 2)}
                y={_in(chair.seat.depth - chair.legs.width / 2)}
                radius={_in(chair.legs.width / 2)}
                stroke='black'
                strokeWidth={1}
                fill={"silver"}
            />
            <Circle
                x={_in(chair.seat.width - chair.legs.width / 2)}
                y={_in(chair.seat.depth - chair.legs.width / 2)}
                radius={_in(chair.legs.width / 2)}
                stroke='black'
                strokeWidth={1}
                fill={"silver"}

            />

        </Layer>
    </Stage >
}