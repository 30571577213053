import { Group, Rect, Shape } from "react-konva"

const fivemm = 0.19685
export function inch(value, largestInch, size) {
    return (size / largestInch) * value
}

export function thicknessOfBlocks(blocks) {
    let number = 0
    for (const block of blocks) {
        number = block.layers + number
    }
    return number * fivemm
}

function DroopRect(props) {
    const _in = props.inch
    const chair = props.chair
    const block = props.block
    const currentY = props.y
    return (
        <Shape
            sceneFunc={(context, shape) => {
                context.beginPath()
                context.moveTo(0, currentY)
                context.lineTo(_in(chair.leftArm.width), currentY)
                context.quadraticCurveTo(
                    _in(chair.seat.width / 2),
                    _in(chair.seat.width * 0.09) + currentY,
                    _in(chair.seat.width) - _in(chair.rightArm.width),
                    currentY
                )
                context.lineTo(_in(chair.seat.width), currentY)
                context.lineTo(
                    _in(chair.seat.width),
                    _in(block.layers * fivemm) + currentY
                )
                context.lineTo(
                    _in(chair.seat.width) - _in(chair.rightArm.width),
                    _in(block.layers * fivemm) + currentY
                )
                context.quadraticCurveTo(
                    _in(chair.seat.width / 2),
                    _in(chair.seat.width * 0.09) +
                        _in(block.layers * fivemm) +
                        currentY,
                    _in(chair.rightArm.width),
                    _in(block.layers * fivemm) + currentY
                )
                context.lineTo(0, _in(block.layers * fivemm) + currentY)
                context.closePath()
                context.fillStrokeShape(shape)
            }}
            stroke="black"
            strokeWidth={1}
            fill={block.color}
        />
    )
}

export function RectForBlocks(props) {
    let currentY = 0
    const blocks = []
    for (const block of props.blocks) {
        if (block.layers === 0) {
            continue
        }
        if (props.droop) {
            blocks.push(
                <DroopRect
                    inch={props.inch}
                    block={block}
                    y={currentY}
                    chair={props.chair}
                />
            )
        } else {
            blocks.push(
                <Rect
                    x={0}
                    y={currentY}
                    height={props.inch(block.layers * fivemm)}
                    stroke="black"
                    width={props.width}
                    strokeWidth={1}
                    fill={block.color}
                />
            )
        }
        currentY = currentY + props.inch(block.layers * fivemm)
    }
    return (
        <Group x={props.x} y={props.y}>
            {blocks}
        </Group>
    )
}

export function cornerRadius(chair, _in) {
    if (chair.radiusCorner) {
        return _in(2)
    }
    return 0
}
