import {cloneDeep} from 'lodash';


export const emptyState = {
    type: 'chaise',
    tuft: {
        radius: 1,
        height: 0.24,
        color: '#F3F3F3'
    },
    seat: {
        width: 30,
        depth: 20,
        blocks: [
            { layers: 18, color: "white" },
        ]
    },
    back: {
        depth: 5,
        width: 18,
        blocks: [
            { layers: 32, color: "white" },
        ]
    },
    leftArm: {
        width: 6,
        blocks: [
            { layers: 12, color: "white" }
        ]
    },
    legs: {
        height: 8,
        width: 5,
        color: 'black'
    },
    radiusCorner:false
}

export const initialState = emptyState



export function reducer(state, action) {
    switch (action.type) {
        case 'CHAISE':
            console.log(action)
            return { ...state, chaise: {...action.payload}}
        default:
            return state
    }
}