import React from 'react';
import { Stage, Layer, Rect, Circle, Group } from 'react-konva';
import { inch , cornerRadius} from './utils'


export default function ArmChairTop(props) {
    const chair = props.chair
    const largestInch = Math.max(chair.seat.depth, chair.seat.width)

    const _in = (i) => { return inch(i, largestInch, props.size) };

    return <Stage width={_in(chair.seat.width) + 2} height={_in(chair.seat.depth) + 2}>
        <Layer>
            <Rect
                x={0}
                y={0}
                width={_in(chair.seat.width)}
                height={_in(chair.seat.depth)}
                fill={chair.seat.blocks[0].color}
                stroke='black'
                strokeWidth={1}
                cornerRadius={cornerRadius(chair,_in)}

            />

            {/*Arms */}
            <Rect
                x={0}
                y={0}
                width={_in(chair.leftArm.width)}
                height={_in(chair.seat.depth)}
                fill={chair.leftArm.blocks[0].color}
                stroke='black'
                strokeWidth={1}
                cornerRadius={cornerRadius(chair,_in)}
            />
            <Rect
                x={_in(chair.seat.width) - _in(chair.rightArm.width)}
                y={0}
                width={_in(chair.rightArm.width)}
                height={_in(chair.seat.depth)}
                fill={chair.rightArm.blocks[0].color}
                stroke='black'
                strokeWidth={1}
                cornerRadius={cornerRadius(chair,_in)}

            />


            { /* Back */}
            <Rect
                x={0}
                y={0}
                width={_in(chair.seat.width)}
                height={_in(chair.back.depth)}
                fill={chair.back.blocks[0].color}
                stroke='black'
                strokeWidth={1}
                cornerRadius={cornerRadius(chair,_in)}
            />


            {/*Tufts*/}
            <Circle
                x={_in(chair.leftArm.width / 2)}
                y={_in(chair.back.depth / 2)}
                radius={inch(1, largestInch, props.size)}
                fill={chair.tuft.color}
                stroke='black'
                strokeWidth={1}
            />
            <Circle
                x={_in(chair.seat.width) - _in(chair.leftArm.width / 2)}
                y={_in(chair.back.depth / 2, largestInch, props.size)}
                radius={_in(chair.tuft.radius)}
                fill={chair.tuft.color}
                stroke='black'
                strokeWidth={1}
            />

            <Circle
                x={_in(chair.leftArm.width / 2)}
                y={_in(chair.seat.depth - 2)}
                radius={_in(chair.tuft.radius)}
                fill={chair.tuft.color}
                stroke='black'
                strokeWidth={1}
            />
            <Circle
                x={_in(chair.seat.width) - _in(chair.leftArm.width / 2)}
                y={_in(chair.seat.depth - 2)}
                radius={_in(chair.tuft.radius, largestInch, props.size)}
                fill={chair.tuft.color}
                stroke='black'
                strokeWidth={1}
            />
        </Layer>
    </Stage >
}