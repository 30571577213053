import {cloneDeep} from 'lodash';


export const emptyState = {
    type: 'sideChair',
    tuft: {
        radius: 1,
        height: 0.24,
        color: '#F3F3F3'
    },
    seat: {
        width: 16,
        depth: 20,
        blocks: [
            { layers: 18, color: "white" },

        ]
    },
    back: {
        depth: 5,
        blocks: [
            { layers: 62, color: "white" },
        ]
    },
    legs: {
        height: 8,
        width: 5,
        color: 'black'
    },
    radiusCorner:false
}

export const initialState = emptyState


const optionOne = cloneDeep(emptyState)
const optionTwo = cloneDeep(emptyState)
optionTwo.seat.blocks[0].layers = 28
optionTwo.legs.height = 6


const optionThree = cloneDeep(emptyState)
optionThree.seat.blocks[0].layers = 48
optionThree.legs.height = 2


export const options = {
    "quarter height": optionOne,
    "half height": optionTwo,
    "full height": optionThree
}


export function reducer(state, action) {
    switch (action.type) {
        case 'SIDE_CHAIR':
            console.log("We got it")
            return { ...state, sideChair: {...action.payload}}
        default:
            return state
    }
}