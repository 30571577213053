import React from 'react';
import { useGlobalState } from "../state/index"
import {cloneDeep} from 'lodash';

import { Front, Side } from '../components/illustrations/Illustrations';

import "../css/flex.css"
import "../css/layouts.css"


export default function Dimensions(props) {
    const globalState = useGlobalState()
    
}