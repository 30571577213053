import React from 'react'
import {useGlobalState} from "../state/index"
import "../css/details.css"

import armChairImg from "../images/views/armchair.jpg" 
import benchImg from "../images/views/bench.jpg"
import chaiseImg from "../images/views/chaise.jpg"
import sideChairImg from "../images/views/sidechair.jpg"
import stoolImg from "../images/views/stool.jpg"

const dims = {
    armChair: '25 7/8" W x 20 9/16" D x 32 5/16" H',
    bench: '32” W x 12” D x 18” H',
    sideChair: '18 3/4" W x 20 9/16" D x 32 5/16" H',
    stool: '17-1/2” W x 17-1/2” D x 18” H',
    chaise: '65" W x 32" D x 35.70 H'
}

function WhateverWithPalette(props){
    if(!props.palette){
        return "whatever"
    }
    const w = ['w','h','a','t','e','v','e','r']
    let items = []
    for(const chr of w){
        const keys = Object.keys(props.palette)
        const color = props.palette[keys[keys.length * Math.random() << 0]];
        items.push(<font style={{color: color}}>{chr}</font>)
    }
    return <span> {items}</span>
}

export default function Details(props){
    const state = useGlobalState()
    let image = null;
    if(props.type === "armChair"){
        image = armChairImg
    }else if(props.type === "bench"){
        image = benchImg
    }else if(props.type === "chaise"){
        image = chaiseImg
    }else if(props.type === "sideChair"){
        image = sideChairImg
    }else if(props.type === "stool"){
        image = stoolImg
    }

    return <div className="details">
        <div className="details-image"><img alt={props.type} src={image} /></div>
        <div className="details-detail">
            <b>Dimensions</b><br /> {dims[props.type]}
            <br />
            ... or  
            <WhateverWithPalette palette={state.state.progress.palette}></WhateverWithPalette>
            <br />
            <button onClick={()=>{window.location="/ar/" + props.type + '.usdz'}}className="ar-button">See in Agumented Reality</button>
        </div>
    </div>
      
}