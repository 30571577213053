import React, { useState } from 'react'
import { useGlobalState, emptyState } from "../state/index"

import { Front, Side, Top, Bottom } from "../components/illustrations/Illustrations"
import Config from "../Config"
import '../css/modal.css'



export default function ChairModal(props) {
    const [side, setSide] = useState('front')
    const globalState = props.state

    const ilProps = {
        vw: 25,
        chair: props.chair,
        type: props.chair.type
    }

    function isActive(handle) {
        if (side === 'front') {
            return true;
        }
        if (side === "top" && handle !== 'down') {
            return false
        }
        if (side === "left" && handle !== 'right') {
            return false
        }
        if (side === "right" && handle !== 'left') {
            return false
        }
        if (side === "bottom" && handle !== 'up') {
            return false
        }
        return true;
    }

    function setView(clicked) {
        if (side == 'front') {
            if (clicked == 'up') {
                setSide('top')
            } else if (clicked == "down") {
                setSide('bottom')
            } else if (clicked == "left") {
                setSide('left')
            } else if (clicked == 'right') {
                setSide('right')
            }
            return;
        }
        if (isActive(clicked)) {
            setSide('front')
        }
    }

    function viewHandleClass(handle) {
        let className = "handle-" + handle
        if (!isActive(handle)) {
            return className + " disabled"
        }
        return className;


    }

    console.log(props.chair)
    return <div>

        <div className="chair-model modal">
            <div className="handle-close" ><span onClick={props.close}>X</span></div>
            <div className="xy-viewport">

                <div className={viewHandleClass("up")} onClick={() => { setView("up") }}>&gt;</div>
                <div className={viewHandleClass("down")} onClick={() => { setView("down") }}>&gt;</div>
                <div className={viewHandleClass("left")} onClick={() => { setView("left") }}>&lt;</div>
                <div className={viewHandleClass("right")} onClick={() => { setView("right") }}>&gt;</div>

                <div className="xy-content">
                    {
                        {
                            'front': <Front {...ilProps} />,
                            'left': <Side right={false} {...ilProps} />,
                            'right': <Side right={true} {...ilProps} />,
                            'top': <Top chair={props.chair} {...ilProps} />,
                            'bottom': <Bottom chair={props.chair} {...ilProps} />
                        }[side]
                    }
                </div>
                <div className="details">
                    <div className="name">
                        {Config.chairs[props.chair.type].name}
                    </div>
                    <div className="subName">
                        <div>
                            {Config.chairs[props.chair.type].dimensions}
                        </div>
                        <div>
                            Starting at: ${Config.chairs[props.chair.type].startingPrice}
                        </div>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button onClick={() => {
                    globalState.dispatch({
                        type: "CHAIR_PROGRESS",
                        payload: {
                            step: "subtype",
                            type: props.chair.type
                        }
                    })
                    props.close()
                    if(props.onDesign){
                        props.onDesign()
                    }
                }}>Design</button>
            </div>
        </div>
        <div className="modal-overlay"></div>
    </div>
}