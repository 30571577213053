import {cloneDeep} from 'lodash';


export const emptyState = {
    type: 'armChair',
    tuft: {
        radius: 1,
        height: 0.24,
        color: '#F3F3F3'
    },
    seat: {
        width: 24,
        depth: 20,
        blocks: [
            { layers: 18, color: "white" },
        ]
    },
    leftArm: {
        width: 6,
        blocks: [
            { layers: 32, color: "white" }
        ]
    },
    rightArm: {
        width: 6,
        blocks: [
            { layers: 32, color: "white" },
        ]
    },
    back: {
        depth: 5,
        blocks: [
            { layers: 36, color: "white" },
        ]
    },
    legs: {
        height: 8,
        width: 5,
        color: 'black'
    }
}

export const initialState = emptyState

const optionOne = cloneDeep(emptyState)
const optionTwo = cloneDeep(emptyState)
optionTwo.seat.blocks[0].layers = 28
optionTwo.legs.height = 6


const optionThree = cloneDeep(emptyState)
optionThree.seat.blocks[0].layers = 48
optionThree.legs.height = 2

export const options = {
    "quarter height": optionOne,
    "half height": optionTwo,
    "full height": optionThree
}

export function reducer(state, action) {
    switch (action.type) {
        case 'ARM_CHAIR':
            return { ...state, armChair: {...action.payload}}
        default:
            return state
    }

}