import React, { createContext, useReducer, useContext } from 'react';
import {
    initialState as armChairInitialState,
    emptyState as armChairEmptyState,
    reducer as armChairReducer,
    options as armChairOptions
} from "./arm-chair"

import {
    initialState as benchInitialState,
    emptyState as benchEmptyState,
    reducer as benchReducer,
    options as benchOptions

} from "./bench"

import {
    initialState as sideChairInitialState,
    emptyState as sideChairEmptyState,
    reducer as sideChairReducer,
    options as sideChairOptions
} from "./side-chair"

import {
    initialState as stoolInitialState,
    emptyState as stoolEmptyState,
    reducer as stoolReducer
} from "./stool"

import {
    initialState as chaiseInitialState,
    emptyState as chaiseEmptyState,
    reducer as chaiseReducer
} from "./chaise"


import {
    initialState as progressInitialState,
    reducer as progressReducer,
} from "./progress"

const reducers = [armChairReducer, benchReducer, sideChairReducer, progressReducer, stoolReducer, chaiseReducer]
let initialState = {
    armChair: armChairInitialState,
    bench: benchInitialState,
    sideChair: sideChairInitialState,
    stool: stoolInitialState,
    chaise: chaiseInitialState,
    progress: progressInitialState
}

export const emptyState = {
    armChair: armChairEmptyState,
    bench: benchEmptyState,
    sideChair: sideChairEmptyState,
    chaise: chaiseEmptyState,
    stool: stoolEmptyState
}

export const options = {
    armChair : armChairOptions,
    sideChair: sideChairOptions,
    bench: benchOptions
}

console.log(chaiseEmptyState)
/**
 * 
 * Logic stuff that should not need changing
 */


const combineReducers = (reducers) =>
    (state = initialState, action) => {
        for (let i = 0; i < reducers.length; i++){
            state = reducers[i](state, action)
        }
        return state;
    }

let store = null;


store = createContext(initialState);

const { Provider } = store;

const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(combineReducers(reducers), initialState)
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const useGlobalState = () => useContext(store);
export { store, StateProvider, useGlobalState }