import React, { useState, useEffect } from 'react'
import '../state/index'
import Start from '../pages/Start'
import Legs from '../pages/Legs'
import Radius from "../pages/Radius"
import Dimensions from "../pages/Dimensions"
import About from "../components/About"
import MailForm from "../components/MailForm"

import { useGlobalState } from "../state/index"
import InfoButton from "../images/info.svg"

import { Switch, Route } from 'react-router-dom'

import insta from "../images/instagram.png"

import '../css/global.css'
import "../css/about.css"


const reg = {
    "type": {
        Page: Start,
        order: 1
    },
    "subtype": {
        Page: Start,
        order: 2
    },
    "legs": {
        Page: Legs,
        order: 3
    },
    "corners": {
        Page: Radius,
        order: 4
    },
    "dimensions": {
        Page: Dimensions,

        order: 5
    },
    "colour": {
        Page: Start,

        order: 6
    },
    "finishes": {
        Page: Start,

        order: 7
    },
    "review": {
        Page: Start,

        order: 8
    },
    "order": {
        Page: Start,
        order: 9
    }
}


function Main(props) {
    const globalState = useGlobalState()
    const currentReg = globalState.state.progress
    const Page = reg[currentReg.step].Page
    const [showAbout, setShowAbout] = useState(false)
    const [showSignup, setShowSignup] = useState(false)

    const routes = [];
    for (const r in reg){
        const P = reg[r].Page;
        routes.push(
            <Route exact path={"/"+r}>
                <P state={globalState}></P>
            </Route>
        )
    }

 
    return (
        <div>
            
          
            <Switch>
                <Route path="/subtype/:type">
                    <Start state={globalState} ></Start>
                </Route>
                {routes}
                <Route>
                    <Page state={globalState} ></Page>
                </Route>
            </Switch>
            <div className="info-button">
                {!showAbout &&
                    <img alt="back button" src={InfoButton} onClick={()=>{setShowAbout(true)}} ></img>
                }
            </div>
            <div className={showAbout ? "about show": "about hidden"}>
                <About close={()=>{setShowAbout(false)}} />
            </div>

            <div className={!showSignup ? "signup-button button": "signup-button form"}>
                {!showSignup &&
                    <div className="signup-text" onClick={()=>setShowSignup(true)}>design yours</div>
                }
                {showSignup &&
                <MailForm close={()=>{setShowSignup(false)}}/>
                }
            </div>
            <div className="blueprints"><a alt="blueprints" href="/blueprints.svg">application blueprint</a> <div className="hbar"> | </div>
                <a href="https://www.instagram.com/stacklab_design/" alt="instagram"><img src={insta} alt="instagram" / ></a>
            </div>

           
        </div>

    );
}

export default Main;
