import React from 'react';
import TypeSelect from "../layouts/TypeSelect"
import { useGlobalState } from "../state/index"

import {Route, Switch, useParams} from 'react-router-dom'

export default function Start(props) {
    const globalState = useGlobalState()
    let selected = null;
    let { type } = useParams();

    
    if (globalState.state.progress.step === "subtype") {
        selected = globalState.state.progress.type
    }
    if(type){
        selected = globalState.state.progress.type
    }
   

    return <div>
        <h1 className="main-title">&nbsp;FELT <span className="underscore">_</span>2.0&nbsp;</h1>
        <h1 className="sub-title"><a href="https://stacklab.ca">&nbsp;STACKLAB&nbsp;</a></h1>
        <Switch>
       
        <Route path="/subtype/:type">
            <TypeSelect selected={selected} state={props.state}></TypeSelect>
        </Route>
        <Route>
            <TypeSelect state={props.state}></TypeSelect>
        </Route>
        </Switch>
        
        </div>
}