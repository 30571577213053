import React from 'react'


export default function About(props){
    return (
        <div>

        <div onClick={props.close} className="close"><div className="close-button">&gt;</div></div>

        <div className="about-content">
        
       
        
        <h2>_2.0</h2>
        <p>
        
        _2.0 is a computer aided system that 
        aggregates and upcycles regional textile waste into 
        configurable consumer goods. It’s comprised of 6 parts: 
        <ol>
            <li>A proprietary parametric design algorithm that enables mass customization. </li>
            <li>A master database that interfaces with partnering manufacturers’ 
                stored waste material inventories.</li>
            <li>An optimization algorithm that qualifies suitable materials for specific configurable products. </li>
            <li>An interactive digital configuration application with real time pricing capabilities.</li>
            <li>An E-commerce portal.</li>
            <li>An automated production, documentation, logistics, and coordination tool. </li>
        </ol>
        </p>
        <h2>FELT_2.0</h2>
        <p>

        As a beta test, Stacklab has partnered with four regional manufacturers 
        (two felted wool suppliers, one post consumer (old scrap) 
        aluminum and powder coating supplier, and one specialized fabricator) 
        to create a broad, customizable collection of home furnishings. 
        This new collection expands on STACKLAB's original Felt Collection, 
        a prototypic furniture system made using felted Merino wool that is sourced 
        from regional manufacturers using an early version of STACKLAB’s 
        custom-developed algorithm. The new Felt_2.0 will 
        represent the fundamental capabilities of the new _2.0 system 
        and act as a proof of concept for future cross-industry collaborations. 
        </p>
        <p>
        Developed in collaboration with STACKLAB alum James Munroe, 
        Toronto-based programmer William Harford (<a href="https://wehsoftware.com">WEH Software</a>) 
        and Devansh Shah, 
        a mechanical engineering and computing technology student from the University of Ottawa. 
        STACKLAB's Configurator enables users to create a wide range of furnishings using a 
        database of felt remnants supplied by Toronto-based Interior Felt and
        New York-based Filzfelt. The felt is supported by an aluminum structural system 
        developed in collaboration with Toronto-based CoFo Design. 
        Improving on the original Felt Collection's virgin steel structural system, 
        the FELT_2.0 uses recycled aluminum that is powder-coated using re-purposed powder.
        </p>
        <p>
        FELT_2.0 will be made completely available to the public in 2021. 
        Through a straightforward laptop, tablet, or smartphone interface, 
        users will be able to configure a flexible range of furnishings and 
        explore an infinite number of colour combinations for their designs. 
        </p>

        <h2>
        DesignTO
        </h2>
        <p>
        STACKLAB's installation for DesignTO features two FELT_2.0 prototypes: 
        a chaise lounge and stool configured using the interactive digital 
        configuration application that STACKLAB calls “The Configurator”. 
        In addition to the physical prototypes, 
        STACKLAB will enable users to interact with several virtual prototypes 
        through an Augmented Reality (AR) portal.  
        </p>

        <h2>
        Thinking bigger Than <i>Just Furniture</i>
        </h2>

        <p>
        <u>Reshoring</u><br/>
        STACKLAB has a long history of supporting and partnering with 
        regional experts in science and craft. With FELT_2.0, 
        Stacklab will further their investment in the local community showcasing 
        Canadian manufacturing and design capabilities on the international stage. 
        </p>


        <p>
        <u>Alternatives to Permanent Tooling</u><br/>
        One of the principal goals of the _2.0 system is to eliminate the need for 
        permanent high-cost tooling such as dies and molds. 
        Reliance on these tools limits manufacturers’ 
        abilities to produce configurable products. 
        Therefore, in partnership with regional manufacturers, 
        STACKLAB will develop alternative variable tooling solutions 
        that optimize for speed and fidelity, and enable mass product customization.
        </p>

        <p>
        <u>Repurposing Waste</u> <br/>
        With the beta launch of FELT_2.0 in 2021, 
        STACKLAB expects to rescue approximately 15,000 sq-ft. 
        of textile waste from regional landfills and and 32,150 cubic inches of
        post-consumer (scrap) aluminum. 
        Without the burden of having to store their manufacturing waste, 
        STACKLAB’s partners will be able to convert storage areas into 
        productive manufacturing space
        </p>

        <p>
        <u>Design Patronage</u><br/>

        STACKLAB is committed to advancements in design and promoting patronage.
         Access to ‘collectible design’ is often limited due to the fact 
         that designers producing limited, conceptual editions must often 
         pass high production costs on to collectors. Of the six features of the _2.0 system, 
         shared material databases, material optimization algorithms, 
         E-commerce and automation will lower base pricing significantly. 
         However, the introduction of a configuration tool, 
         specifically- a configuration tool with real-time pricing feedback- 
         will enable collectors to enter the market comfortably and with a degree of control. 
        </p>

       <h2>
       FELT_2.0 Launch
       </h2>
       <p>
       STACKLAB will launch FELT_2.0 in 2021 in partnership with 
       New York Design Gallery Maison Gerard. 
       Further details will be released soon. 
       </p>

        
        <h2>
        STACKLAB
        </h2>
        <p>
        STACKLAB is a multidisciplinary art-and-design studio founded by Jeff Forrest, 
        in 2013. The studio pursues conceptual projects spanning a range of 
        scales and disciplines by working with regional experts in material, 
        craft, science, and technology. STACKLAB has two divisions, OBJECT, 
        which includes furniture and art objects; and PROJECT, which focuses on architecture, 
        interior design, and installation. 
        </p>
        <p>
        <u>_2.0 Design Stack:</u>
        <ul>
            <li>Jeff Forrest</li>
            <li><a href="http://oaat.ca">James Munroe</a></li>
            <li><a href="https://www.wehsoftware.com">WEH Software</a></li>
            <li><a href="https://www.devansh.ca">Devansh Shah</a></li>
        </ul>    
        <u>FELT_2.0 Design Stack:</u>
        <ul>
            <li>Jeff Forrest</li>
            <li>James Munroe</li>
            <li><a href="https://wehsoftware.com">WEH Software</a></li>
            <li>Devansh Shah</li>
            <li>Kyle Brill</li>
            <li><a href="https://www.jessecowan.com">Jesse Cowan</a></li>
            <li>Vahid Eshraghi</li>
            <br></br>
            <li><a href="https://www.cofodesign.com">COFO Design</a></li>
            <li><a href="https://interiorfelt.com">Interior Felt</a></li>
            <li><a href="https://www.filzfelt.com">Filzfelt</a></li>
        </ul>
        <u>Special Thanks:</u>
        <ul>
        <li><a href="https://www.maisongerard.com">Maison Gerard</a></li>
        <li>Owen Mueller</li>
        <li>Sophie Quick</li>
        <li>Hoda Farahani</li>
        </ul>    
        </p>
    </div>
    </div>
    )
}