import React from 'react';
import { Stage, Layer, Rect, Group } from 'react-konva';
import { inch, thicknessOfBlocks, RectForBlocks } from './utils'


export default function BenchTop(props) {
    const chair = props.chair
    const height = chair.legs.height +
        thicknessOfBlocks(chair.seat.blocks) +
        1 //Tuft height 

    const largestInch = Math.max(height, chair.seat.depth)
    const _in = (i) => { return inch(i, largestInch, props.size) };


    return <Stage width={_in(chair.seat.depth) + 2} height={_in(height) + 2}>
        <Layer >
            <Group>
                {/*Tufts*/}

                <Rect
                    x={_in(chair.seat.depth - (chair.legs.width / 2) - chair.tuft.radius)}
                    y={0}
                    width={_in(chair.tuft.radius * 2)}
                    height={_in(chair.tuft.height)}
                    fill={chair.tuft.color}
                    strokeWidth={1}
                    stroke="black"
                />
                <Rect
                    x={_in((chair.legs.width / 2) - chair.tuft.radius)}
                    y={0}
                    width={_in(chair.tuft.radius * 2)}
                    height={_in(chair.tuft.height)}
                    fill={chair.tuft.color}
                    strokeWidth={1}
                    stroke="black"
                />

            </Group>
            <Group x={0} y={_in(chair.tuft.height)}>

                <RectForBlocks
                    x={0}
                    y={0}
                    blocks={chair.seat.blocks}
                    inch={(i) => { return _in(i) }}
                    width={_in(chair.seat.depth)}
                />
            </Group>
            <Group
                x={0}
                y={_in(thicknessOfBlocks(chair.seat.blocks) +
                    chair.tuft.height)}>
                <Rect
                    x={0}
                    y={0}
                    width={_in(chair.legs.width)}
                    height={_in(chair.legs.height)}
                    fill={chair.legs.color}
                    strokeWidth={1}
                    stroke="black"
                />

                <Rect
                    x={_in(chair.seat.depth - chair.legs.width)}
                    y={0}
                    width={_in(chair.legs.width)}
                    height={_in(chair.legs.height)}
                    fill={chair.legs.color}
                    strokeWidth={1}
                    stroke="black"
                />

            </Group>
        </Layer>
    </Stage >
}