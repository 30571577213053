import React, { useState, useEffect } from 'react'

import './css/app.css';
import './state/index'
import { StateProvider } from "./state/index"
import Main from "./layouts/Main"
import { useGlobalState } from "./state/index"
import { BrowserRouter } from "react-router-dom";



function App() {
  const globalState = useGlobalState()

  return (
    <StateProvider>
      <BrowserRouter>
      <Main />
      </BrowserRouter>
    </StateProvider>
  );
}

export default App;
