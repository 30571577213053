import {cloneDeep} from 'lodash';


export const emptyState = {
    type: 'stool',
    tuft: {
        radius: 1,
        height: 0.24,
        color: '#F3F3F3'
    },
    seat: {
        width: 18,
        depth: 10,
        blocks: [
            { layers: 18, color: "white" }
        ]
    },
    legs: {
        height: 8,
        width: 8,
        color: 'black'
    },
    radiusCorner:true
}

export const initialState = emptyState




export function reducer(state, action) {
    switch (action.type) {
        case 'STOOL':
            return { ...state, stool: {...action.payload} }
        default:
            return state
    }

}